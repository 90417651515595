import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";

import "react-toastify/dist/ReactToastify.css";

import { ErrorHandlingProvider } from "./contexts/ErrorHandlingContext";
import { BalanceProvider } from "./contexts/BalanceContext";
import { HealthProvider } from "./contexts/HealthContext";
import { BrowserPreferenceProvider } from "./contexts/BrowserPreferenceContext";
import { HouseProvider } from "./contexts/HouseContext";
import { AggregatedBalancesProvider } from "./contexts/AggregatedBalancesContext";
import { BetstreamingProvider } from "./contexts/BetstreamingContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { ProgramProvider } from "./contexts/ProgramContext";
import { ModalProvider } from "./contexts/ModalContext";
import { NavProvider } from "./contexts/NavContext";
import { UserPreferenceProvider } from "./contexts/UserPreferenceContext";
import { BannerProvider } from "./contexts/BannerContext";
import { AudioProvider } from "./contexts/AudioContext";

import { ToasterContext } from "./contexts/ToasterContext";
import { MainContainer } from "./pages/MainContainer";
import { GamePagePreloader } from "./pages/preloaders/GamePagePreloader";
import { WrappedWalletProvider } from "./contexts/WrappedWalletContext";
import { SolanaWalletProvider } from "./contexts/SolanaWalletProvider";
import { PlayerTokenProvider } from "./contexts/PlayerTokenContext";
import { SessionAuthorityProvider } from "./contexts/SessionAuthorityContext";
import { TopBannerProvider } from "./contexts/TopBannerContext";
import QueryClientProvider from "./contexts/QueryClientProvider";
import { DivvyProvider } from "./contexts/DivvyContext";

const Error404Page = lazy(() => import("./pages/Error404Page"));
const GameContainer = lazy(() => import("./containers/GameContainer"));

export const App = () => {
  return (
    <ToasterContext.Provider value={toast}>
      <ErrorHandlingProvider>
        <QueryClientProvider>
          <NetworkProvider>
            <HealthProvider>
              <ProgramProvider>
                <SolanaWalletProvider>
                  <WrappedWalletProvider>
                    <DivvyProvider>
                      <UserPreferenceProvider>
                        <BrowserPreferenceProvider>
                          <BalanceProvider>
                            <HouseProvider>
                              <SessionAuthorityProvider>
                                <PlayerTokenProvider>
                                  <BetstreamingProvider>
                                    <AggregatedBalancesProvider>
                                      <ModalProvider>
                                        <ReactModalProvider
                                          rootComponent={TransitionGroup}
                                        >
                                          <NavProvider>
                                            <TopBannerProvider>
                                              <BannerProvider>
                                                <AudioProvider>
                                                  <BrowserRouter>
                                                    <Routes>
                                                      <Route
                                                        path=""
                                                        element={
                                                          <MainContainer />
                                                        }
                                                      >
                                                        <Route
                                                          index
                                                          element={
                                                            <Suspense
                                                              fallback={
                                                                <GamePagePreloader />
                                                              }
                                                            >
                                                              <GameContainer />
                                                            </Suspense>
                                                          }
                                                        />

                                                        <Route
                                                          path="*"
                                                          element={
                                                            <Suspense
                                                              fallback={<></>}
                                                            >
                                                              <Error404Page
                                                                backHome={``}
                                                              />
                                                            </Suspense>
                                                          }
                                                        />
                                                      </Route>
                                                    </Routes>
                                                  </BrowserRouter>
                                                </AudioProvider>
                                              </BannerProvider>
                                            </TopBannerProvider>
                                          </NavProvider>
                                        </ReactModalProvider>
                                      </ModalProvider>
                                    </AggregatedBalancesProvider>
                                  </BetstreamingProvider>
                                </PlayerTokenProvider>
                              </SessionAuthorityProvider>
                            </HouseProvider>
                          </BalanceProvider>
                        </BrowserPreferenceProvider>
                      </UserPreferenceProvider>
                    </DivvyProvider>
                  </WrappedWalletProvider>
                </SolanaWalletProvider>
              </ProgramProvider>
            </HealthProvider>
          </NetworkProvider>
        </QueryClientProvider>
      </ErrorHandlingProvider>
    </ToasterContext.Provider>
  );
};
